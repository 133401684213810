@import 'src/scss/variables';

.score-contribution{
  .content{
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    p{
      font-weight: 600;
      text-align: center;
      font-size: 1.5rem;
      display: inline-block;
      background-color: lighten($color: $text-color, $amount: 66);
      padding: 0.125rem 0.5rem;
      border-radius: 4px;
    }
  }
}