@import 'src/scss/variables';
@import 'src/scss/mixins';

.subs-wrapper{
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid rgba($color: #000000, $alpha: .08);
  background-color: lighten($text-color, 69);
  height: 100%;
  .title{
    color: $text-color;
    font-weight: 600;
    margin-bottom: 0.125rem;
    &::after{
      content: '';
      height: 3px;
      width: 3rem;
      margin-top: 0.375rem;
      background-color: rgba($color: #000000, $alpha: .08);
      display: block;
    }
  }
}

.letter-rail{
  display: flex;
  margin-bottom: 0.25rem;
  .box{
    flex-grow: 1;
    text-align: center;
    font-weight: 600;
  }
}

.scores{
  &.technical{
    .skill-name{
      margin-bottom: 1rem;
      font-weight: 600;
    }
  }
  &.personal{

  }
}