@import 'src/scss/mixins';

.filter-toggle{
  display: none;
  margin-bottom: 0;
  @include media-breakpoint-range(mobile){
    display: block;
    margin-bottom: 1rem;
  }
}

.filter-wrapper{
  transition: all 0.4s ease;
  height: auto;
  @include media-breakpoint-range(mobile){
    height: 0;
    overflow-y: hidden;
  }
  &.open{
    transition: height 0.4s ease;
    height: 300px;
  }
}