@import 'src/scss/variables';

.title{
  margin-bottom: 2rem;
  text-align: center;
  h1{
    font-size: 1.75rem;
    color: $text-color;
  }
}

.submit{
  width: 50%;
  margin-top: 1.25rem;
}

.forgot{
  margin-top: 2.5rem;
}