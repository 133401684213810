@import 'src/scss/variables';
@import 'src/scss/mixins';

.skill-card {
  border-radius: 6px;
  padding: 0.5rem;
  margin-top: -0.5rem;
}

.technical {
  background-color: lighten($bg-fill-dark, 68);
  border: 1px solid lighten($bg-fill-dark, 68);

}

.personal {
  background-color: lighten($bg-fill-support, 30);
  border: 1px solid lighten($bg-fill-support, 30);
}