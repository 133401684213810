@import "src/scss/variables";
@import "src/scss/mixins";

.skill-search-bar{
  .wrapper{
    position: relative;
    .search{
      border: 1px solid;
      border-color: transparent;
      height: 45px;
      border-radius: 6px;
      padding-left: 2.75rem;
      @include box-shadow(level-1);
    }
    label{
      color: rgba($color: #000000, $alpha: .6);
    }
    i{
      position: absolute;
      left: 0.75rem;
      top: 0.75rem;
    }
  }
}