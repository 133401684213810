@import 'src/scss/mixins';

.skills-header{
  height: 100%;
  p{
    margin-bottom: 0;
    &.name{
      font-size: 1.25rem;
      font-weight: 600;
      @include media-breakpoint-range(mobile){
        max-width: 90%;
        margin-bottom: 1rem;
        margin-top: -1.5rem;
      }
    }
  }
}