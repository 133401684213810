.title{
  margin-bottom: 2rem;
  text-align: center;
  h1{
    font-size: 1.75rem;
  }
}

.submit{
  width: 50%;
  margin-top: 1.25rem;
}

.back-to-login{
  margin-top: 2.5rem;
}