@import 'src/scss/variables';
@import 'src/scss/mixins';

.sub-skills{
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid rgba($color: #000000, $alpha: .08);
  background-color: lighten($text-color, 69);
  min-height: 250px;
  height: 100%;
  @include media-breakpoint-range(mobile){
    margin-bottom: 1rem;
    height: auto;
  }
  .title{
    color: $text-color;
    font-weight: 600;
    margin-bottom: 1rem;
    &::after{
      content: '';
      height: 3px;
      width: 3rem;
      margin-top: 0.375rem;
      background-color: rgba($color: #000000, $alpha: .08);
      display: block;
    }
  }
}


.tag-cloud{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 0.625rem;
  .tag {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    border: 1px solid rgba($color: #000000, $alpha: .08);
    background-color: lighten($color: $text-color, $amount: 66);
    min-width: 31.5%;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    height: 1.75rem;
    .wrap{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      div{
        line-height: 0;
        &.name{
          margin-right: 0.25rem;
        }
        &.actions{
          margin-left: 0.25rem;
        }
        .tag-icon{
          display: inline-block;
          vertical-align: middle;
          margin-left:0.25rem;
          cursor: pointer;
          i{
            font-size: 1.25rem;
            &:hover{
              color: $bg-fill-primary;
            }
          }
        }
      }
    }
  }
}