@import 'src/scss/variables';
@import 'src/scss/mixins';

.rail{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  .box{
    flex-grow: 1;
    text-align: center;
    p{
      margin-bottom: 0;
    }
    button{
      width: 100%;
      border-color: lighten($bg-fill-secondary, 5);
      height: 16px;
      border-left: 0;
      border-radius: 0;
      background-color: lighten($bg-fill-secondary, 42);
      @include media-breakpoint-range(mobile){
        min-width: auto;
      }
      &:hover{
        background-color: lighten($bg-fill-secondary, 30);
      }
      &.active{
        background-color: lighten($bg-fill-secondary, 5);
        border-color: lighten($bg-fill-secondary, 5);
      }
      &:disabled{
        &.read-only{
          background-color: lighten($color: $text-color, $amount: 55);
          box-shadow: none;
          pointer-events: none;
          &.active{
            background-color: lighten($bg-fill-secondary, 5);
          }
          opacity: 1;
          border-color: rgba($color: #000000, $alpha: .12);
        }
      }
    }
    &:first-child{
      button{
        border-radius: 3px 0px 0px 3px;
        border-left: 1px solid lighten($bg-fill-secondary, 5);
      }
    }
    &:last-child{
      button{
        border-radius: 0px 3px 3px 0px;
      }
    }
  }
}