@import 'src/scss/variables';
@import 'src/scss/mixins';

.skill-card{
  border-radius: 6px;
  padding: 0.5rem;
  background-color: lighten($text-color, 66);
  border: 1px solid rgba($color: #000000, $alpha: .08);
  @include media-breakpoint-range(mobile){
    padding: 0.5rem;
  }
}
.title{
  color: $text-color;
  font-weight: 600;
  margin-bottom: 1rem;
  &::after{
    content: '';
    height: 3px;
    width: 3rem;
    margin-top: 0.375rem;
    background-color: rgba($color: #000000, $alpha: .08);
    display: block;
  }
}