@import 'src/scss/variables';

.loader{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: white, $alpha: .6);
  padding: 2rem;
  flex-direction: column;
  p{
    color: lighten($color: $text-color, $amount: 40);
    margin-top: 1rem;
    margin-bottom: 0;
  }
}

.overlay{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: $bg-fill-dark, $alpha: .6);
  padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}