@import "src/scss/variables";
@import "src/scss/mixins";

.layout {
  min-height: 100vh;

  main {
    padding-left: 16.66667%;
    padding-top: 8.75rem;
    min-height: 100vh;
    padding-bottom: 28px;
    transition: padding 0.4s ease;

    @include media-breakpoint-range(mobile) {
      padding-top: 10rem;
      padding-left: 0;
    }

    &.full-width {
      padding-left: 10px;
    }

    &.closed {
      padding-left: 90px;

      @include media-breakpoint-range(mobile) {
        padding-left: 0;
      }
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: lighten($color: $text-color, $amount: 66);
      height: 100%;
    }
  }

  .app-bar-wrap {
    background-color: #f3f4f5e8;
    position: fixed;
    padding-left: calc(16.66667% + 0.625rem);
    top: 0;
    width: 100%;
    right: 0;
    z-index: 2;
    padding-top: 2rem;
    padding-bottom: 2rem;
    transition: padding 0.4s ease;

    &.full-width {
      padding-left: 10px;
    }

    @include media-breakpoint-range(mobile) {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0.875rem;
      background: rgba(255, 255, 255, 1);
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(243, 244, 245, 0.94) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(243, 244, 245, 0.94)));
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(243, 244, 245, 0.94) 100%);
      background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(243, 244, 245, 0.94) 100%);
      background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(243, 244, 245, 0.94) 100%);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(243, 244, 245, 0.94) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f3f4f5', GradientType=0);
    }

    &.closed {
      padding-left: calc(90px + .875rem);

      @include media-breakpoint-range(mobile) {
        padding-left: 0.875rem;
      }
    }
  }

  .side-bar {
    background-color: white;
    height: 100vh;
    position: fixed;
    z-index: 3;
    padding-top: 1rem;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    transition-property: width, padding, overflow;
    background-image: url('../../App/sidebar-back-2.svg');
    background-position: center 105%;
    background-repeat: no-repeat;
    background-size: 60%;
    width: 16.66667%;
    @include box-shadow(level-3);

    @include media-breakpoint-range(mobile) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }

    &:hover {
      .toggle {
        opacity: 1;
      }
    }

    &.closed {
      width: 90px;

      @include media-breakpoint-range(mobile) {
        width: 0;
        padding: 0;
        overflow: hidden;
      }

      .nav-wrap {
        padding-left: 0rem;
        padding-right: 0rem;
      }

      .pill {
        span {
          opacity: 0;
          width: 0;
          overflow: hidden;
        }

        i {
          margin-right: 0;
          font-size: 1.25rem;

          @include media-breakpoint-range(mobile) {
            font-size: 1rem;
          }
        }

        &.active {
          i {
            color: $bg-fill-primary;
          }
        }
      }

      .close {
        button {
          transform: translateY(3rem);
        }
      }
    }

    .logo {
      padding: 1.75rem 1rem 2rem 2rem;

      @include media-breakpoint-range(mobile) {
        display: none;
      }

      img {
        &.long {
          width: 70%;
        }

        &.short {
          height: 47.25px;
          margin-left: -0.625rem;
          width: 47.25px;
        }
      }
    }

    .nav-wrap {
      @include media-breakpoint-range(mobile) {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
      }
    }

    .pill {
      color: lighten($text-color, 30);
      font-size: 0.9375rem;
      letter-spacing: 2px;
      font-family: $title-font;
      position: relative;
      padding: 1.125rem 1rem;
      font-weight: 700;
      text-transform: uppercase;
      transition: all 0.2s ease;
      width: 100%;
      white-space: nowrap;
      text-overflow: hidden;

      i {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
        font-size: 1rem;
        transition: all 0.4s ease;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        opacity: 1;
        transition-property: opacity, width, overflow;
        transition-duration: 0.4s;
        transition-timing-function: ease;
        width: 100%;
      }

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        left: -1rem;
        width: 5px;
        height: 100%;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        background-color: $bg-fill-primary;
        z-index: -1;
        margin-left: -192px;
        transition: all 0.3s ease;
      }

      &.active {
        background-color: transparent;
        color: $bg-fill-primary;

        i {
          color: $bg-fill-primary;
        }

        &::before {
          margin-left: 0;
        }
      }

      &:hover {
        color: $bg-fill-primary;
      }
    }

    .toggle {
      min-width: auto;
      width: 1.5rem;
      height: 5rem;
      border-radius: 4px;
      color: $bg-fill-primary;
      background-color: lighten($color: $bg-fill-primary, $amount: 30);
      border-color: lighten($color: $bg-fill-primary, $amount: 20);
      padding: 0;
      text-align: center;
      margin-left: auto;
      margin-right: -29px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;
      opacity: 0;
      transition: all 0.2s ease;
      position: relative;
      transition: all 0.4s ease;

      &:hover {
        background-color: lighten($color: $bg-fill-primary, $amount: 20);
        transform: translateX(-5px);
      }

      @include media-breakpoint-range(large-display-up) {
        margin-top: 7rem;
      }

      @include media-breakpoint-range(mobile) {
        opacity: 1;
        position: fixed;
        right: 0;
        top: 7rem;
        margin-right: 0;
        height: 35px;
        width: 35px;
        border-radius: 6px 0px 0px 6px;

        &:hover {
          transform: none;
        }
      }

      i {
        font-size: 2rem;
      }

      &.closed {
        @include media-breakpoint-range(mobile) {
          left: 0;
          right: auto;
          border-radius: 0px 6px 6px 0px;

          &:hover {
            transform: none;
            @include box-shadow(level-1);
          }
        }

        &:hover {
          transform: translateX(5px);
        }

        &:focused {
          box-shadow: none;
        }
      }
    }
  }
}

.version {
  position: absolute;
  bottom: 0;
  left: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  background: white;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
}
