@import 'src/scss/variables';

.main-button-wrap {
  button {
    margin-right: 0.5rem;
    margin-bottom: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.controls {
  button {
    margin-left: 0.25rem;

    &:last-child {
      margin-left: 0;
    }
  }
}

.deadline {
  font-weight: 500;
  margin-top: 1rem;

  p {
    margin-bottom: .25rem;
  }
}

.note-meta {
  margin-bottom: 0.625rem;
}

.note-title {
  p {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0;
  }
}


.badge {
  height: 1.5rem;
  font-size: 0.875rem;
  border-radius: 4px;
  font-weight: 500;
  color: $text-color;
  padding: 0.125rem 0.5rem;
  display: inline-block;
  margin-top: 0.25rem;
  margin-right: 1rem;

  &.achieved {
    background-color: lighten($success-color, 20);
  }

  &.failed {
    background-color: lighten($danger-color, 20);
  }

  &.ongoing {
    background-color: lighten($warning-color, 20);
  }
}
