@import "../../../scss/mixins";
@import "../../../scss/variables";

.auth-wrapper {
  @include box-shadow(level-4);
  padding: 1.875rem 1.25rem;
  border-radius: 0.375rem;
  background-color: white;
}

.background {
  background-image: url("../../../images/auth-background.svg");
  background-position: 90% 70%;
  background-repeat: no-repeat;
  background-size: 35%;
  min-height: 100vh;
  background-color: $bg-fill-light;
}

.logo {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  @include media-breakpoint-range(mobile){
    margin-top: 2rem;
    padding-bottom: 1rem;
  }
  img{
    width: 250px;
    @include media-breakpoint-range(mobile){
      width: 200px;
    }
  }
}
