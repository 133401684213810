@import 'src/scss/variables';
@import 'src/scss/mixins';

.legend {
  display: flex;
  margin-bottom: 2rem;
  .structural {
    display: flex;
    align-items: center;
    &::before{
      content: '';
      background-color: lighten($bg-fill-support, 30);
      display: inline-block;
      border-radius: 8px;
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-right: 0.5rem;
    }
    span{
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      margin-left: 0.25rem;
      i{
        opacity: 0.3;
        font-size: 1rem;
      }
    }
  }
  .fundamental {
    margin-right: 2rem;
    display: flex;
    align-items: center;
    &::before{
      content: '';
      background-color: lighten($bg-fill-support, 4);
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      vertical-align: middle;
      margin-right: 0.5rem;
    }
    span{
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      margin-left: 0.25rem;
      i{
        opacity: 0.3;
        font-size: 1rem;
      }
    }
  }
}

.scroll-gradient{
  &::after{
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    width: 4rem;
    background: rgba(255,255,255,0);
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.53) 53%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(53%, rgba(246,246,246,0.53)), color-stop(100%, rgba(255,255,255,1)));
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.53) 53%, rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.53) 53%, rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.53) 53%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(246,246,246,0.53) 53%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
  }
}

.rail{
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
  @include media-breakpoint-range(mobile){
    overflow-x: auto;
    position: relative;
  }
  .box{
    border-radius: 6px;
    padding: 1rem 0.5rem;
    margin: 0 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 62px;
    color: $text-color;
    font-size: 0.875rem;
    font-weight: 500;
    text-align: center;
    @include media-breakpoint-range(mobile){
      min-width: max-content;
    }
    &.technical{
      background-color: lighten($bg-fill-dark, 68);
    }
    &.personal{
      background-color: lighten($bg-fill-support, 30);
    }
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
    &.highlighted{
      background-color: lighten($bg-fill-support, 4);
    }
  }
}
