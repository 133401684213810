@import 'src/scss/variables';
@import 'src/scss/mixins';

.pagination{
  margin-top: 0.5rem;
  .wrapper{
    .page-size{
      label{
        font-size: 0.75rem;
        margin-bottom: 0;
        width: 100%;
      }
      select{
        width: fit-content;
      }
    }
    .items{
      nav{
        height: 100%;
      }
      ul{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        li{
          display: inline-block;
          line-height: 1;
          button{
            min-width: 30px !important;
            height: 30px;
            margin-left: .5rem;
            padding: 0;
            min-width: auto;
            line-height: 8px;
            box-shadow: none;
            border-color: transparent;
            color: lighten($color: $text-color, $amount: 10);
            background-color: white;
            &:hover{
              background-color: lighten($color: $bg-fill-primary, $amount: 30);
              color: $bg-fill-primary;
            }
          }
          label{
            margin-bottom: 0;
            background-color: rgba($color: #000000, $alpha: .16);
            padding: 0.25rem 0.5rem;
            border-radius: 15px;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}