@import 'src/scss/variables';
@import 'src/scss/mixins';

.notes-wrapper {
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid rgba($color: #000000, $alpha: .08);
  background-color: lighten($text-color, 69);
  margin-top: 1rem;
  height: calc(100% - 1rem);

  .title {
    color: $text-color;
    font-weight: 600;
    margin-bottom: 0.125rem;

    &::after {
      content: '';
      height: 3px;
      width: 3rem;
      margin-top: 0.375rem;
      background-color: rgba($color: #000000, $alpha: .08);
      display: block;
    }
  }

  .tab-buttons {
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1rem;

    button {
      min-width: auto;
      background-color: white;
      border-radius: 0;
      border: none;
      background-color: white;
      color: $bg-fill-primary;
      text-transform: capitalize;
      box-shadow: none;
      font-weight: bold;
      border-radius: 20px;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      border: 1px solid lighten($bg-fill-primary, 20);
      height: 35px;
      font-size: 0.875rem;

      &:hover {
        background-color: lighten($color: $bg-fill-primary, $amount: 30);
      }

      &.active {
        background-color: lighten($bg-fill-primary, 30);
        color: $bg-fill-primary;

        &:hover {
          background-color: lighten($bg-fill-primary, 30);
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        border-right: 1px solid inherit;
        margin-right: 0;
      }
    }
  }

  .notes-margin-catcher {
    &>div {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.attachment-widget {
  text-align: center;
  padding-bottom: 10px;

  i {
    vertical-align: middle;
  }
}

.custom-addon {
  position: absolute;
  right: 0px;
  bottom: 0px;
  cursor: pointer;

  a {
    text-decoration: none;
    color: $bg-fill-primary;
  }

  span {
    background-color: transparent;
    border: 0px;
    padding: 6px 0px;
    padding-right: 6px;
  }

  i {
    vertical-align: middle;
    color: $bg-fill-primary;
    background-color: white;

    &:hover {
      color: darken($color: $bg-fill-primary, $amount: 10)
    }
  }

  .alert i {
    margin: 0px;
    color: $warning-color;

    &:hover {
      color: darken($color: $warning-color, $amount: 10)
    }
  }
}

.link-validation-error {
  input {
    border: 1px solid;
    border-color: red;
  }
}
