
.graph-container{
  padding: 0 2rem 2rem;
  background-color: white;
  position: relative;
  border-radius: 6px;
  margin-bottom: -1rem;
}
.distribution-graph {
  height: 72vh;
  min-height: 72vh;
  width: 100%;
  min-width: 100%;
  overflow: hidden;
  border-radius: 6px;
}

.x-label{
  top: 50%;
  left: 1rem;
  transform: rotate(-90deg);
  margin-top: -10px;
  margin-left: -75px;
  position: absolute;
  width: 150px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}
.y-label{
  right: 2rem;
  bottom: 1rem;
  margin-bottom: -10px;
  position: absolute;
  width: 150px;
  left: 50%;
  margin-left: -75px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}
