@import 'src/scss/variables';

.content-wrapper{
  opacity: 1;
  margin-left: -1rem;
  margin-right: -1rem;
}

.table-below{
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-top: 1px solid rgba($color: #000000, $alpha: .08);
  padding-top: 1.5rem;
}