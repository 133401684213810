$text-color: #434a54;
$bg-fill-light: #fafafa;
$bg-fill-grey: #e8ecf1;
$bg-fill-primary: #6c5feb;
$bg-fill-secondary: #1d6efe;
$bg-fill-dark: #011e4a;
$bg-fill-support: #00b5cc;
$bg-fill-highlight: #abd6fe;
$success-color: #34c759;
$warning-color: #ff9500;
$danger-color: #ff453a;

$body-font: 'Roboto', sans-serif;
$title-font: 'Roboto Condensed', sans-serif;
