@import './variables';

.validation-error-button {
  color: $danger-color;
  border: 1px solid lighten($color: $danger-color, $amount: 20);
  background-color: lighten($color: $danger-color, $amount: 30);

  &:hover {
    background-color: lighten($color: $danger-color, $amount: 30);
  }
}

.padding-top-lg {
  padding-top: 30px;
}
