@import "./variables";
@import "./mixins";

html {
  font-size: 16px;
}

body {
  font-family: $body-font;
  color: $text-color;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: subpixel-antialiased !important;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $title-font;
  color: rgba($color: $text-color, $alpha: 0.8);
  font-weight: 400;
}

label{
  font-size: 1rem;
  font-weight: bold;
  color: lighten($text-color, 5);
}


.search-bar_{
  &_control{
    min-height: 45px;
    border-color: transparent;
    border-width: 1px;
    border-radius: 6px;
    @include box-shadow(level-1);
    transition: all 0.2s ease;
    padding-left: 2rem;
    &:hover{
      border-color: rgba($color: $text-color, $alpha: 0.08);
      cursor: pointer;
    }
  }
  &_value-container{
    padding-left: 1.25rem;
  }
  &_menu{
    border: none;
    @include box-shadow(level-2);
  }
  &_option{
    color: $bg-fill-primary;
    &--is-focused{
      font-weight: 500;
      background-color: lighten($color: $bg-fill-secondary, $amount: 40);
    }
  }
}

.feedback-text{
  color: $danger-color;
  font-weight: 500;
  font-size: 80%;
  width: 100%;
  margin-top: 0.25rem;
}
.custom-react-select{
  &.has-error{
    .sp-select_, .sp-select-filter_{
      &_control{
        border-color: lighten($color: $danger-color, $amount: 10);
        &--is-focused{
          border-color: darken($color: $danger-color, $amount: 10);
          &:hover{
            border-color: darken($color: $danger-color, $amount: 10);
          }
        }
      }
    }
  }
  .sp-select_, .sp-select-filter_{
    &_indicator-separator{
      background-color: transparent;
    }
    &_value-container{
      cursor: text;
    }
    &_control{
      border-color: rgba($color: #000000, $alpha: .08);
      height: 40px;
      transition: all 0.2s ease;
      &:hover{
        border-color: rgba($color: #000000, $alpha: .2);
      }
      &--is-focused{
        border-color: lighten($bg-fill-primary, 10);
        box-shadow: none;
        &:hover{
          border-color: $bg-fill-primary;
        }
      }
      &--menu-is-open{
        border-color: lighten($bg-fill-primary, 10);
        box-shadow: none;
        &:hover{
          border-color: $bg-fill-primary;
        }
      }
    }
    &_menu{
      border: none;
      @include box-shadow(level-2);
    }
    &_option{
      color: $bg-fill-primary;
      &--is-focused{        
        background-color: lighten($color: $bg-fill-secondary, $amount: 40);
      }
      &--is-selected{
        font-weight: 500;
        background-color: lighten($color: $bg-fill-secondary, $amount: 30);
      }
    }
    
  }
}

.sp-select-filter_{
  &_control{
    @include box-shadow(level-1);
    height: 45px !important;
    border-color: transparent !important;
    border-radius: 6px;
  }
}


//quadrant styles

.rv-xy-plot_{
  &_inner{
    background-color: lighten($color: $bg-fill-primary, $amount: 34);
  }
  &_grid-lines__line{
    stroke: $bg-fill-grey;
  }
  &_axis__title{
    text{
      fill: $text-color;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

//date-picker styles

.react-datepicker {
  border: 1px solid rgba($color: $text-color, $alpha: 0.25);
  font-family: $body-font;
  &-wrapper {
    display: block;
  }
  &__header {
    background-color: $bg-fill-grey;
    border-bottom: 1px solid
      rgba($color: $text-color, $alpha: 0.25);
  }
  &__navigation {
    min-width: auto;
    box-shadow: none;
    border-radius: 4px;
    &:hover{
      transform: none;
      box-shadow: none;
    }
    &--next {
      border-left-color: rgba($color: $text-color, $alpha: 0.5);
      &:hover{
        border-left-color: rgba($color: $text-color, $alpha: 0.75);
      }
    }
    &--previous {
      border-right-color: rgba($color: $text-color, $alpha: 0.5);
      &:hover{
        border-right-color: rgba($color: $text-color, $alpha: 0.75);
      }
    }
  }
  &__day {
    color: $text-color;
    &--selected {
      background-color: lighten($bg-fill-primary, 30);
      color: $bg-fill-primary;
    }
    &--keyboard-selected {
      background-color: lighten($bg-fill-primary, 30);
      color: $bg-fill-primary;
    }
    &--disabled {
      color: rgba($color: #000000, $alpha: 0.2);
    }
  }
  &__current-month {
    font-family: $title-font;
    font-weight: bold;
    text-transform: uppercase;
  }
  &__day-name {
    color: $text-color;
    font-weight: 500;
  }
  &-popper[data-placement^="bottom"] {
    .react-datepicker__triangle {
      border-bottom-color: rgba($color: $text-color, $alpha: 0.1);
      &::before {
        border-bottom-color: rgba(
          $color: $text-color,
          $alpha: 0.1
        );
      }
    }
  }
}

//Toast

.Toastify{
  button{
    min-width: auto;
    box-shadow: none;
    color: white;
    opacity: 1;
    &:hover{
      box-shadow: none;
    }
  }
  .Toastify__toast{
    border-radius: 6px;
    &--success{
      background-color: $success-color;
    }
    &--danger{
      background-color: $danger-color;
    }
    &--warning{
      background-color: $warning-color;
    }
    &--default{
      background-color: lighten($text-color, 30);
    }
    .toast-header{
      background-color: transparent;
      border: none;
      color: white;
      text-shadow: 0px 1px 1px rgba($color: #000000, $alpha: .16);
      strong{
        font-weight: 500;
        font-size: 1rem;
      }
    }
    .toast{
      border: none;
      box-shadow: none;
    }
  }
}

//checkbox

.custom-checkbox{
  &.custom-control{
    .custom-control{
      &-label{
        cursor: pointer;
        font-weight: 500;
        &::before{
          border-color: lighten($bg-fill-primary, 20) !important;
          background-color: $bg-fill-grey !important;
          border-radius: 50%;
          cursor: pointer;
          top: 0.125rem;
          left: -1.5rem;
          width: 1.25rem;
          height: 1.25rem;
        }
        &::after{
          top: 0.125rem;
          left: -1.5rem;
          width: 1.25rem;
          height: 1.25rem;
        }
      }
      &-input{
        &:checked ~ .custom-control-label{
          &::before{
            border-color: $bg-fill-primary !important;
            background-color: $bg-fill-primary !important;
          }
        }
        &:disabled ~ .custom-control-label{
          cursor: not-allowed;
        }
      }
    }
    
  }
}
