@import "src/scss/variables";
@import "src/scss/mixins";


.wrapper{
  background-color: white;
  padding: 2rem;
  position: relative;
  @include box-shadow(level-1);
  span{
    &.x{
      top: 1rem;
      left: 50%;
      margin-left: -75px;
      margin-top: -10px;
      position: absolute;
      width: 150px;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
    }
    &.y{
      right: 1rem;
      margin-right: -75px;
      transform: rotate(-90deg);
      top: 50%;
      margin-top: -10px;
      position: absolute;
      width: 150px;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
    }

  }
}
.quadrant-container {
  height: calc( 100vh - 14.5rem );
  min-height: calc( 100vh - 14.5rem );
  width: 100%;
  min-width: 100%;
  overflow: hidden;
  border-radius: 6px;
  &.reduce-height{
    height: calc( 100vh - 20.3rem );
    min-height: calc( 100vh - 20.3rem );
  }
}

.zoom-container {
  position: absolute;
  bottom: 100px;
  right: 3rem;
  z-index: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  @include box-shadow(level-1);
  border-radius: 4px;
}

.value-label{
  background-color: rgba($color: $bg-fill-dark, $alpha: .7);
  color: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: inline-block;
  border-radius: 4px;
}

.detail-card{
  background-color: white;
  border-radius: 4px;
  padding: 1rem;
  @include box-shadow(level-2);
  .picture{
    width: 75px;
    height: 75px;
    border-radius: 37.5px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    border: 2px solid rgba($color: #000000, $alpha: .16);
    img{
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
  .general{
    .name{
      font-family: $body-font;
      font-size: 1.25rem;
      margin-bottom: 0.25rem;
      color: $text-color;
      font-weight: 500;
    }
    .title{
      font-size: 0.875rem;
      color: lighten($text-color, 30);
    }
  }
  .additional{
    p{
      font-size: 0.875rem;
      margin-bottom: 0.25rem;
      color: lighten($color: $text-color, $amount: 40);
    }
  }
  .footer{
    text-align: left;
    margin-top: 1.25rem;
    a{
      font-weight: 500;
      text-transform: uppercase;
      &:hover{
        text-decoration: none;
        transform: translateY(-1px);
        color: darken($color: $bg-fill-primary, $amount: 10);
      }
    }
  }
}
