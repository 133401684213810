@import 'src/scss/variables';
@import 'src/scss/mixins';

.accordion{
  border-radius: 4px;
  border: 1px solid rgba($color: #000000, $alpha: .08);
  background-color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  transition: all 0.2s ease;
  .toggle{
    cursor: pointer;
    display: flex;
    align-items: center;
    @include media-breakpoint-range(mobile){
      align-items: flex-start;
      justify-content: flex-end;
    }
    &:hover{
      color: $bg-fill-primary;
    }
  }
  .details{
    border-top: 1px solid rgba($color: $bg-fill-primary, $alpha: .1);
    margin-top: 0.5rem;
    padding-top: 1.75rem;
    padding-bottom: 1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    @include media-breakpoint-range(mobile){
      padding-left: 0;
      padding-right: 0;
      padding-top: 1rem;
      padding-bottom: 0.75rem;
    }
  }
}