@import './variables';


.circle-button {
  line-height: 1;
}

button {
  height: 40px;
  min-width: 80px;
  color: $text-color;
  border-radius: 22px;
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  border: 1px solid;
  padding-left: 1rem;
  padding-right: 1rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transform: translateY(-1px);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
    pointer-events: none;
  }

  &.small-button {
    height: 30px;
    line-height: 1.75;
    min-width: auto;
  }

  &.icon-button {
    min-width: 30px !important;
    height: 30px;
    padding: 0;
    min-width: auto;
    line-height: 8px;
    box-shadow: none;

    &.dark {
      border-color: transparent;
      color: lighten($color: $text-color, $amount: 10);
      background-color: white;

      &:hover {
        background-color: lighten($color: $bg-fill-primary, $amount: 30);
        color: $bg-fill-primary;
      }
    }

    &.light {
      border-color: transparent;
      color: white;
      background-color: transparent;

      &:hover {
        background-color: rgba($color: #ffffff, $alpha: .2);
      }
    }

    &:hover {
      box-shadow: none;
    }
  }

  &.text-button {
    padding: 0;
    border: none;
    box-shadow: none;
    background-color: transparent;
    font-weight: 700;

    &:hover {
      background-color: transparent;
    }

    &.danger-text {
      color: $danger-color;

      &:hover {
        color: darken($color: $danger-color, $amount: 10);
      }
    }

    &.primary-text {
      color: $bg-fill-primary;

      &:hover {
        color: darken($color: $bg-fill-primary, $amount: 10);
      }
    }

    &.secondary-text {
      color: $bg-fill-secondary;

      &:hover {
        color: darken($color: $bg-fill-secondary, $amount: 10);
      }
    }
  }

  &.primary {
    background-color: $bg-fill-primary;
    border-color: $bg-fill-primary;
    color: white;

    &:hover {
      background-color: darken($color: $bg-fill-primary, $amount: 10);
    }

    &.line {
      background-color: white;
      border-color: $bg-fill-primary;
      color: $bg-fill-primary;

      &:hover {
        background-color: lighten($color: $bg-fill-primary, $amount: 30);
      }
    }
  }

  &.secondary {
    background-color: $bg-fill-secondary;
    border-color: $bg-fill-secondary;
    color: white;

    &:hover {
      background-color: darken($color: $bg-fill-secondary, $amount: 10);
    }

    &.line {
      background-color: white;
      border-color: $bg-fill-secondary;
      color: $bg-fill-secondary;

      &:hover {
        background-color: lighten($color: $bg-fill-secondary, $amount: 35);
      }
    }
  }

  &.default {
    background-color: lighten($color: $text-color, $amount: 10);
    border-color: lighten($color: $text-color, $amount: 10);
    color: white;

    &:hover {
      background-color: $text-color;
    }

    &.line {
      background-color: white;
      border-color: lighten($color: $text-color, $amount: 10);
      color: lighten($color: $text-color, $amount: 10);

      &:hover {
        background-color: lighten($color: $text-color, $amount: 60);
      }
    }
  }

  &.primary-non-upper {
    text-transform: none;
    background-color: $bg-fill-primary;
    border-color: $bg-fill-primary;
    color: white;

    &:hover {
      background-color: darken($color: $bg-fill-primary, $amount: 10);
    }

    &.line {
      background-color: white;
      border-color: $bg-fill-primary;
      color: $bg-fill-primary;

      &:hover {
        background-color: lighten($color: $bg-fill-primary, $amount: 30);
      }
    }
  }
}
