@import './mixins';
@import './variables';

.table-wrapper {
  background-color: white;
  @include box-shadow(level-1);
  border-radius: 6px;
  .rdt_Table {
    height: auto;
    .rdt_TableHead {
      .rdt_TableHeadRow {
        min-height: 50px;
        max-height: 50px;
        background-color: $bg-fill-light;
        border-radius: 6px 6px 0 0;
        .rdt_TableCol {
          .rdt_TableCol_Sortable {
            font-size: 1rem;
            font-weight: bold;
            color: $text-color;
          }
        }
      }
    }
    .rdt_TableBody {
      .rdt_TableRow {
        min-height: 60px;
        transition: all 0.3s ease;
        .rdt_TableCell {
          font-size: 1rem;
          .table-action {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
  .rdt_TableFooter{
    display: flex;
    align-items: center;
    min-height: 50px;
    max-height: 50px;
    background-color: $bg-fill-light;
    border-radius: 0 0 6px 6px;
    span{
      color: $text-color;
      font-size: .875rem;
      font-weight: 500;
    }
    select{
      color: $text-color;
      font-size: 1rem;
    }
    button{
      width: 30px !important;
      height: 30px;
      padding: 0;
      min-width: auto;
      line-height: 8px;
      box-shadow: none;
      color: $text-color;
    }
  }
}
