@import 'src/scss/variables';

.widget{
  margin-bottom: 1rem;
  button{
    width: 100%;
    background-color: white;
    min-width: auto;
    color: $bg-fill-primary;
    text-transform: capitalize;
    box-shadow: none;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid lighten($bg-fill-primary, 20);
    height: 40px;
    font-size: 0.875rem;
    &:hover{
      background-color: lighten($color: $bg-fill-primary, $amount: 30);
      box-shadow: none;
    }
  }
}

.close{
  margin-top: 0.3rem;
}