@import "src/scss/variables";
@import "src/scss/mixins";

.app-bar {
  .mobile-header {
    display: none;
    padding: 1.25rem 2rem 1rem;
    height: 70px;

    &::after {
      content: '';
      width: 25%;
      height: 1px;
      background-color: rgba($color: #000000, $alpha: .1);
      display: block;
      margin: 0.75rem auto 0;
    }

    @include media-breakpoint-range(mobile) {
      display: block;
    }

    img {
      width: 60%;
      margin: 0 auto;
      display: block;
    }
  }

  .logo {
    padding: 0px 1.75rem;

    @include media-breakpoint-range(mobile) {
      display: none;
    }

    img {
      &.long {
        width: 70%;
        height: 47.25px;
      }

      &.short {
        height: 47.25px;
        margin-left: -0.625rem;
        width: 47.25px;
      }
    }
  }

  .title {
    font-family: $title-font;
    font-size: 1.75rem;
    color: $text-color;
    text-transform: uppercase;

    @include media-breakpoint-range(mobile) {
      width: 100%;
      text-align: center;
      font-size: 1.5rem;
      margin-right: 0;
      padding-top: 1rem;
      min-height: 57px;
    }
  }

  .user-options {
    position: absolute;
    right: 1rem;

    @include media-breakpoint-range(mobile) {
      margin-top: -4rem;
      background: white;
      margin-right: -2rem;
      border-radius: 30px 0 0 30px;
      @include box-shadow(level-2);
    }

    .drop-down {
      display: flex;
      align-items: center;
      justify-content: center;

      &[aria-expanded="true"] {
        &:hover {
          border-radius: 6px 6px 0 0;
        }
      }

      &::after {
        border-radius: 10px;
      }

      .picture {
        margin-right: 0.625rem;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        border: 2px solid rgba($color: #000000, $alpha: .16);
        overflow: hidden;

        @include media-breakpoint-range(mobile) {
          width: 40px;
          height: 40px;
          margin-right: 0.25rem;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      .name {
        font-size: 1.125rem;
        color: $text-color;

        @include media-breakpoint-range(mobile) {
          display: none;
        }
      }
    }

    .drop-down-menu {
      border: none;
      min-width: 100%;
      @include box-shadow(level-2);
      border-radius: 6px;
      padding-top: 5rem;
      margin-top: -4.5rem;
      z-index: -1;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: lighten($color: $text-color, $amount: 60);

        @include media-breakpoint-range(mobile) {
          display: none;
        }
      }

      @include media-breakpoint-range(mobile) {
        color: $text-color;
        right: 0.5rem;
        margin-top: 0.5rem;
        left: auto;
        width: auto;
        padding-top: 0.5rem;
      }

      a {
        line-height: 2;
        color: $bg-fill-primary;
        font-weight: 500;

        &:hover {
          background-color: lighten($color: $bg-fill-secondary, $amount: 40);
        }
      }

      span {
        display: none;
        font-size: 8px;
        font-weight: lighter;

        &:hover {
          display: block;
        }
      }

      button {
        font-size: 1rem;
        box-shadow: none;
        height: auto;
        text-transform: capitalize;
        border-radius: 0;
        color: $bg-fill-primary;
        font-weight: 500;
        line-height: 2.3;

        &:hover {
          background-color: lighten($color: $bg-fill-secondary, $amount: 40);
        }
      }
    }
  }
}


.top-bar {
  color: lighten($text-color, 30);
  font-size: 0.9375rem;
  letter-spacing: 2px;
  font-family: $title-font;
  position: relative;
  padding: 1.125rem 1rem;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.2s ease;
  width: 100%;
  white-space: nowrap;
  text-overflow: hidden;

  &.active {
    background-color: transparent;
    color: $bg-fill-primary;

    i {
      color: $bg-fill-primary;
    }

    &::before {
      margin-left: 0;
    }
  }

  &:hover {
    color: $bg-fill-primary;
  }
}
