@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';


@mixin media-breakpoint-range($device) {
  @if $device == small-mobile {
    @media (max-width: 370px) { @content; }
  }
  @if $device == mobile {
    @media (max-width: 599px) { @content; }
  }
  @if $device == mobile-landscape {
    @media (orientation: landscape) and (max-width: 670px)   { @content; }
  }
  @else if $device == tab-up {
    @media (min-width: 600px) { @content; }
  }
  @else if $device == tab-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @else if $device == desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @else if $device == large-display-up {
    @media (min-width: 1800px) { @content; }
  }
}

@mixin box-shadow($level) {
  @if $level == level-1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.16);
  }
  @if $level == level-2 {
    box-shadow: 0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.16);
  }
  @if $level == level-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.12), 0 6px 6px rgba(0,0,0,0.16);
  }
  @if $level == level-4 {
    box-shadow: 0 14px 28px rgba(0,0,0,0.12), 0 10px 10px rgba(0,0,0,0.16);
  }
  @if $level == level-5 {
    box-shadow: 0 19px 38px rgba(0,0,0,0.09), 0 15px 12px rgba(0,0,0,0.16);
  }
}

@mixin linear-gradient($color1, $color2) {
  background: $color1;
  background: -moz-linear-gradient(top, $color1 0%, $color2 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, $color1), color-stop(100%, $color2));
  background: -webkit-linear-gradient(top, $color1 0%, $color2 100%);
  background: -o-linear-gradient(top, $color1 0%, $color2 100%);
  background: -ms-linear-gradient(top, $color1 0%, $color2 100%);
  background: linear-gradient(to bottom, $color1 0%, $color2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$color1, endColorstr=$color2, GradientType=0 );
}
