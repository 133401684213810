@import 'src/scss/variables';

.body-text{
  font-weight: 400;
  font-size: 1.125rem;
  text-align: center;
  color: lighten($color: $text-color, $amount: 10);
  margin-bottom: 0;
  margin-top: 1rem;
}

.icon{
  text-align: center;
  i{
    color: $warning-color;
    font-size: 5rem;
    line-height: 0.8;
  }
}

.top-alert{
  border-radius: 4px;
  color: $text-color;
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
}