@import "src/scss/variables";
@import "src/scss/mixins";

.tag-cloud{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
  .tag {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    border: 1px solid rgba($color: #000000, $alpha: .08);
    background-color: lighten($color: $text-color, $amount: 66);
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}


.settings{
 .alert{
   background-color: $text-color;
   padding: .5rem 1rem;
   border: 1px solid rgba($color: #000000, $alpha: .08);
   border-radius: 4px;
   @include box-shadow(level-1);
   display: none;
   justify-content: space-between;
   align-items: center;
   p{
    color: white;
    margin-bottom: 0;
   }
  &.show{
    display: flex;
  }
 }

 .card{
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid rgba($color: #000000, $alpha: .1);
  background-color: lighten($text-color, 69);
  margin-top: 1rem;
  .title{
    color: $text-color;
    font-weight: 600;
    margin-bottom: 0.125rem;
    &::after{
      content: '';
      height: 3px;
      width: 3rem;
      margin-top: 0.375rem;
      background-color: rgba($color: #000000, $alpha: .08);
      display: block;
    }
  }
  .progress{
    display: flex;
    margin-top: 1rem;
    .section{
      flex-grow: 1;
      flex-shrink: 1;
      p{
        text-align: center;
        font-size: 1.125rem;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .bar{
        width: 100%;
        height: 5rem;
        color: $text-color;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3.12rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &:first-child{
        .bar{
          border-radius: 4px 0px 0px 4px;
        }
      }
      &:last-child{
        .bar{
          border-radius: 0px 4px 4px 0px;
        }
      }
      &.technical{
        .bar{
          background-color: lighten($bg-fill-dark, 68);
        }
      }
      &.personal{
        .bar{
          background-color: lighten($bg-fill-support, 30);
        }
      }
    }
  }
 }
}
