@import 'src/scss/variables';
@import 'src/scss/mixins';

.skill-progress-wrap{
  height: 100%;
  @include media-breakpoint-range(mobile){
    height: auto;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 1rem;
  }
  .skill-progress{
    border-radius: 4px;
    padding: 2rem 1rem 1rem;
    border: 1px solid rgba($color: #000000, $alpha: .08);
    background-color: lighten($text-color, 69);
    height: 100%;
    span{
      &.x{
        top: 1rem;
        left: 1rem;
        margin-top: -8px;
      }
      &.y{
        right: 1rem;
        bottom: calc( 100% - 210px);
        z-index: 1;
      }
      position: absolute;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }
    .time-line{
      min-height: 190px;
      height: 190px;
    }
    .data{
      padding-top: 1rem;
      .label{
        color: lighten($color: $text-color, $amount: 40);
        font-size: 0.875rem;
      }
      .value{
        display: block;
        font-size: 1.25rem;
        font-weight: 500;
      }
    }
  }
}