@import 'src/scss/mixins';
@import 'src/scss/variables';

.heading{
  color: $text-color;
  font-weight: 600;
  margin-bottom: 1rem;
  &::after{
    content: '';
    height: 3px;
    width: 3rem;
    margin-top: 0.375rem;
    background-color: rgba($color: #000000, $alpha: .08);
    display: block;
  }
}