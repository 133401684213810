@import "src/scss/variables";
@import "src/scss/mixins";

.title {
  margin-bottom: 2rem;
  text-align: center;

  h1 {
    font-size: 1.75rem;
  }
}

.submit {
  width: 50%;
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
}

.wrapper {
  padding: 1rem;

  .title {
    margin-bottom: 20px;
  }
}
