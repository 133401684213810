@import './variables';
@import './mixins';

.form-control {
  border-color: rgba($color: #000000, $alpha: .08);
  &input, select{
    height: 40px;
  }
  transition: all 0.2s ease;
  &:hover{
    border-color: rgba($color: #000000, $alpha: .2);
  }
  &:focus{
    box-shadow: none;
    border-color: lighten($bg-fill-primary, 10);
  }
  &.is-invalid{
    border-color: lighten($danger-color, 10);
    &:focus{
      box-shadow: none;
      border-color: darken($color: $danger-color, $amount: 10);
    }
  }
}

.invalid-feedback{
  color: $danger-color;
  font-weight: 500;
}

.btn {
  height: 40px;
  min-width: 112px;
  border-radius: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-family: $title-font;
  text-transform: uppercase;
  font-size: 1.125rem;
}

button[class*="outline"]{
  background-color: white;
}

button[class*="-sm"]{
  height: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  min-width: auto;
  font-size: 1rem;
  min-width: 70px;
}

.modal{
  .modal-dialog{
    .modal-content{
      .modal-header{
        padding: 1.5rem 1.75rem;
        border-bottom: none;
        justify-content: center;
        background-color: rgba($color: #ffffff, $alpha: .8);
        h5{
          font-size: 1.75rem;
          text-align: center;
          color: $text-color;
          text-transform: uppercase;
          max-width: 80%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        button{
          min-width: 30px !important;
          height: 30px;
          padding: 0;
          line-height: 8px;
          box-shadow: none;
          border-color: transparent;
          color: lighten($color: $text-color, $amount: 10);
          background-color: white;
          margin: 0;
          opacity: 1;
          position: absolute;
          right: 29px;
          top: 29px;
          &:hover{
            background-color: lighten($color: $bg-fill-primary, $amount: 30);
            color: $bg-fill-primary;
          }
          span{
            font-size: 2rem;
            font-weight: lighter;
          }
        }
      }
      .modal-body{
        padding: 1.5rem 1.75rem;
      }
      .modal-footer{
        padding: 1.5rem 1.75rem;
        border-top: none;
        justify-content: center;
        button{
          margin-right: 0.5rem !important;
          margin-left: 0.5rem !important;
        }
      }
    }
  }
}

.alert{
  button{
    &.close{
      min-width: auto;
      box-shadow: none;
    }
  }
}

