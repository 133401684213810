@import "src/scss/variables";

.note-meta {
  margin-bottom: 0.625rem;
}

.note-title {
  p {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.badge {
  height: 1.5rem;
  font-size: 0.875rem;
  border-radius: 4px;
  font-weight: 500;
  color: $text-color;
  padding: 0.125rem 0.5rem;
  display: inline-block;
  margin-top: 0.25rem;
  margin-right: 1rem;
  &.positive {
    background-color: lighten($success-color, 20);
  }
  &.negative {
    background-color: lighten($danger-color, 20);
  }
  &.neutral {
    background-color: lighten($text-color, 50);
  }
}

.attachment {
  background-color: lighten($color: $bg-fill-primary, $amount: 30);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  color: $bg-fill-primary;
  width: 100%;
  transition: transform 0.3s ease;
  i {
    margin-right: 0.5rem;
  }
  &:hover {
    text-decoration: none;
    // color: darken($color: $text-color, $amount: 10);
    // transform: translateY(-1px);
  }
}

.details-section {
  font-size: 14px;
}
