// Your variable overrides
// $body-bg: #000;
// $body-color: #111;

// Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:700|Roboto:300,400,500,700&display=swap');
@import url("https://fonts.googleapis.com/icon?family=Material+Icons+Round");

html {
  @import "./scss/variables";
  @import "./scss/overrides";
  @import "./scss/theme";
  @import "./scss/icons";
  @import "./scss/buttons";
  @import "./scss/tables";
  @import "./scss/common";
}
