@import 'src/scss/variables';

.no-data{
  display: flex;
  width: 100%;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p{
    color: lighten($color: $text-color, $amount: 55);
    font-size: 1.5rem;
    font-weight: 600;
  }
}