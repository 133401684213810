@import 'src/scss/variables';
@import 'src/scss/mixins';

.score-guide{
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid rgba($color: #000000, $alpha: .1);
  background-color: lighten($text-color, 69);
  width: 100%;
  .title{
    color: $text-color;
    font-weight: 600;
    margin-bottom: 1rem;
    &::after{
      content: '';
      height: 3px;
      width: 3rem;
      margin-top: 0.375rem;
      background-color: rgba($color: #000000, $alpha: .08);
      display: block;
    }
  }
  .nav-tabs{
    border-bottom: none;
    position: relative;
    height: 100%;
    border-right: 0;
    @include media-breakpoint-range(mobile){
      flex-direction: row !important;
      flex-wrap: nowrap;
      max-width: 100%;
      overflow-x: auto;
      justify-content: flex-start;
    }
    .nav-item{
      margin-bottom: 0;
      display: inline-block;
      vertical-align: bottom;
      @include media-breakpoint-range(mobile){
        min-width: fit-content;
        margin-right: 0.5rem;
      }
      .nav-link{
        position: relative;
        border: none;
        border-radius: 20px;
        text-align: center;
        cursor: pointer;
        transition: all 0.2s ease;
        color: $bg-fill-primary;
        font-weight: 500;
        font-size: 0.875rem;
        background-color: white;
        border: 1px solid lighten($bg-fill-primary, 20);
        margin-bottom: 0.5rem;
        &:hover{
          background-color: lighten($color: $bg-fill-secondary, $amount: 40);
        }
        &.active{
          background-color: lighten($bg-fill-primary, 30);
          color: $bg-fill-primary;
        }
      }
    }
  }
  .tab-content{
    border: 1px solid rgba($color: $bg-fill-primary, $alpha: .1);;
    border-radius: 0 2px 2px 0;
    padding: 1rem;
    background-color: white;
    .description{
      .sub-title{
        font-size: 0.875rem;
        margin-top: 0;
      }
    }
    .section{
      .sub-title{
        font-size: 0.875rem;
        margin-top: 0;
      }
      p{
        @include media-breakpoint-range(mobile){
          margin-bottom: 0;
        }
      }
    }
  }
}

.scroll-gradient{
  position: relative;
  &::after{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 3rem;
    background: rgba(255,255,255,0);
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.53) 53%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(53%, rgba(246,246,246,0.53)), color-stop(100%, rgba(255,255,255,1)));
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.53) 53%, rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.53) 53%, rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.53) 53%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(246,246,246,0.53) 53%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
  }
  @include media-breakpoint-range(tab-up){
    display: none;
  }
}
