@import 'src/scss/variables';
@import 'src/scss/mixins';

.goals-wrapper{
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid rgba($color: #000000, $alpha: .08);
  background-color: lighten($text-color, 69);
  margin-top: 1rem;
  height: calc( 100% - 1rem );
  margin-right: -1rem;
  .title{
    color: $text-color;
    font-weight: 600;
    margin-bottom: 0.125rem;
    &::after{
      content: '';
      height: 3px;
      width: 3rem;
      margin-top: 0.375rem;
      background-color: rgba($color: #000000, $alpha: .08);
      display: block;
    }
  }
  .tab-buttons{
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1rem;
    button{
      min-width: auto;
      background-color: white;
      color: $bg-fill-primary;
      text-transform: capitalize;
      box-shadow: none;
      font-weight: bold;
      border-radius: 20px;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      border: 1px solid lighten($bg-fill-primary, 20);
      height: 35px;
      font-size: 0.875rem;
      &:hover{
        background-color: lighten($color: $bg-fill-primary, $amount: 30);
      }
      &.active{
        background-color: lighten($bg-fill-primary, 30);
        color: $bg-fill-primary;
        &:hover{
          background-color: lighten($bg-fill-primary, 30);
        }
      }
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        border-right: 1px solid inherit;
        margin-right: 0;
      }
    }
  }
  .goals-margin-catcher{
    &>div{
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}