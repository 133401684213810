@import 'src/scss/variables';
@import 'src/scss/mixins';

.score-guide-preview{
  border-radius: 4px;
  padding: 1.5rem 2rem;
  border: 1px solid rgba($color: #000000, $alpha: .08);
  background-color: lighten($text-color, 69);
  height: 100%;
  .wrapper{
    .text{
      h2{
        font-weight: 500;
        font-family: $body-font;
        font-size: 1rem;
        color: $text-color;
      }
      p{
        margin-bottom: 1rem;
      }
    }
  }
}

.nav-tabs{
  height: 100%;
  border: none;
  .nav-item{
    border: none;
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
    &::after{
      content: '';
      display: inline-block;
      width: 20px;
      height: 2px;   
    }
    &.active{
      &::after{
        background-color: rgba($color: #000000, $alpha: .08);
      }
      .nav-link{
        background-color: lighten($bg-fill-primary, 30);
        color: $bg-fill-primary;
      }
    }
    .nav-link{
      width: 40px;
      height: 40px;
      cursor: pointer;
      width: 40px;
      height: 40px;
      background-color: white;
      cursor: pointer;
      padding: 0;
      line-height: 2.8;
      text-align: center;
      font-weight: 500;
      font-size: 0.875rem;
      border-radius: 20px;
      border: 1px solid lighten($bg-fill-primary, 20);
      color: $bg-fill-primary;
      transition: all 0.2s ease;
      &:hover{
        background-color: lighten($color: $bg-fill-primary, $amount: 30);
        color: $bg-fill-primary;
      }
    }
  }
}

.tab-content{
  border: 2px solid transparent;
  border-left-color: rgba($color: #000000, $alpha: .08);
  padding: 0 1rem;
  .score-label{
    font-family: $body-font;
    margin-bottom: 0.625rem;
    font-size: 1.125rem;
    margin-top: .125rem;
    font-weight: 500;
    color: $text-color;
  }
  .description{
    .sub-title{
      font-size: 0.875rem;
      margin-top: 0;
    }
  }
  .section{
    .sub-title{
      font-size: 0.875rem;
      margin-top: 0;
    }
  }
}