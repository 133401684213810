@import 'src/scss/_variables.scss';

.result-text {
  p {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
  }

  button {
    margin-left: 0.5rem;
  }
}
