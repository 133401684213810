@import 'src/scss/variables';
@import 'src/scss/mixins';

.nav-tabs{
  border-bottom: none;
  position: relative;
  padding-left: 1rem;
  .nav-item{
    margin-bottom: 0;
    display: inline-block;
    vertical-align: bottom;
    @include media-breakpoint-range(mobile){
      width: 50%;
    }
    .nav-link{
      position: relative;
      border: none;
      cursor: pointer;
      font-size: 1.125rem;
      font-family: $title-font;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $text-color;
      transition: all 0.2s ease-in;
      @include media-breakpoint-range(mobile){
        text-align: center;
        font-size: 1rem;
      }
      &::after{
        content: '';
        display: block;       
        position: absolute;
        height: 3px;
        border-radius: 2px;
        background-color: $bg-fill-primary;
        bottom: 0;
        left: 0;
        opacity: 0;
        width: 0px;
        transition: all 0.2s ease-in;
      }
      &:hover{
        color: lighten($bg-fill-primary, 5);
        border-color: transparent;
        &::after{
          opacity: 0;
          width: 100%;
        }
      }
      &.active{
        background-color: transparent;
        color: $bg-fill-primary;
        &::after{
          opacity: 1;
          width: 100%;;
        }
      }
    }
  }
}

.tab-content{
  @include box-shadow(level-1);
  border-radius: 6px;
  padding: 2rem 1rem 1rem;
  background-color: white;
}