@import 'src/scss/variables';
@import 'src/scss/mixins';

.top-skills{
  @include media-breakpoint-range(mobile){
    &::after{
      content: '';
      position: absolute;
      right: -.875rem;
      top: 1rem;
      height: calc( 100% - 2rem);
      width: 4rem;
      background: rgba(255,255,255,0);
      background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.53) 53%, rgba(255,255,255,1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(53%, rgba(246,246,246,0.53)), color-stop(100%, rgba(255,255,255,1)));
      background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.53) 53%, rgba(255,255,255,1) 100%);
      background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.53) 53%, rgba(255,255,255,1) 100%);
      background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.53) 53%, rgba(255,255,255,1) 100%);
      background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(246,246,246,0.53) 53%, rgba(255,255,255,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
    }
  }
  .wrapper{
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include media-breakpoint-range(mobile){
      max-width: calc( 100vw - 2rem);
      width: calc( 100vw - 2rem);
      overflow-x: auto;
    }
    .rail{
      display: flex;
      align-items: center;
      max-width: 50%;
      @include media-breakpoint-range(mobile){
        max-width: none;
      }
      &.technical{
        justify-content: flex-start;
        .box{
          background-color: lighten($bg-fill-dark, 68);
          &:first-child{
            margin-left: 0;
          }
        }
      }
      &.personal{
        justify-content: flex-start;
        @include media-breakpoint-range(mobile){
          padding-right: 2rem;
        }
        .box{
          background-color: lighten($bg-fill-support, 30);
        }
      }
      .box{
        border-radius: 6px;
        padding: 1rem 0.5rem;
        margin: 0 0.25rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 62px;
        flex-shrink: 1;
        color: $text-color;
        font-size: 0.875rem;
        text-align: center;
        border-color: transparent;
        text-align: center;
        border-color: transparent;
        height: 62px;
        text-transform: capitalize;
        font-weight: 500;
        transition-property: height, margin, border-radius;
        transition-timing-function: ease;
        transition-duration: 0.2s;
        box-shadow: none;
        &:hover{
          transform: none;
          box-shadow: none;
          height: 78px;
          margin-bottom: -1rem;
          border-radius: 6px 6px 0 0;
        }
        &.highlighted {
          height: 78px;
          margin-bottom: -1rem;
          border-radius: 6px 6px 0 0;
        }
      }
    }
  }
}
