@import 'src/scss/variables';
@import 'src/scss/mixins';

.newButton {
  height: 40px;
  cursor: pointer;
  line-height: 40px;
  margin-bottom: 0;
  transition: all 0.3s ease;
  background-color: white;
  border: 1px solid lighten($bg-fill-primary, 20);
  color: $bg-fill-primary;
  text-align: center;
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
  min-width: 200px;
  word-wrap: break-word;
  &:hover {
    background-color: lighten($color: $bg-fill-primary, $amount: 30);
    transform: translateY(-1px);
  }
}

.detailView {
  font-size: 12px;
  .error {
    margin: 0.5rem 0;
    font-size: 14px;
    color: $danger-color;
  }
}

.buttonWrap [type=file] {
  display: block;
  cursor: inherit;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;

  &:hover {
    z-index: -1;
  }
}

.buttonWrap {
  position: relative;
  min-height: 40px;
  overflow-wrap: inherit;
}
