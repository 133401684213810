@import 'src/scss/variables';

.tagline{
  border: 1px solid rgba($color: #000000, $alpha: .08);
  padding: 1rem;
  border-radius: 4px;
  background-color: lighten($text-color, 69);
  p{
    margin-bottom: 0;
  }
  .tagline-title{
    font-size: 1rem;
    font-weight: 600;
  }
  .description{
    font-size: 1rem;
    color: lighten($color: $text-color, $amount: 20);
  }
}