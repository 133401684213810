@import "src/scss/variables";

.search-bar-wrap {
  position: relative;
  margin-bottom: 1rem;
  button {
    background-color: transparent;
    border-color: transparent;
    min-width: 32px !important;
    height: 32px;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  label {
    font-weight: bold;
    font-size: 0.875rem;
  }
  .button-wrap {
    position: absolute;
    right: 1.5rem;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    .line {
      width: 1px;
      height: 16px;
      background-color: lighten($text-color, 50);
      display: inline-block;
      margin-right: 0.25rem;
    }
  }
  .prepend-button-wrap {
    position: absolute;
    left: 1.5rem;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
  }
}
