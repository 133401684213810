@import 'src/scss/variables';

.individual-quadrant-wrap{
  border-radius: 4px;
  padding: 2rem;
  border: 1px solid rgba($color: #000000, $alpha: .08);
  background-color: lighten($text-color, 69);
  height: 100%;
  position: relative;
  span{
    &.x{
      top: 1rem;
      left: 50%;
      margin-left: -75px;
      margin-top: -8px;
    }
    &.y{
      right: 1rem;
      margin-right: -75px;
      transform: rotate(-90deg);
      top: 50%;
      margin-top: -8px;
    }
    position: absolute;
    width: 150px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.quadrant{
  min-height: 240px;
  height: 240px;
  width: 100%;
  min-width: 100%;
  overflow: hidden;
  border-radius: 6px;
}

