@import 'src/scss/variables';
@import 'src/scss/mixins';

.goal-stripe {
  background-color: white;
  cursor: pointer;
  @include box-shadow(level-1);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 0.75rem;
  transition: all 0.2s ease;
  &:hover {
    @include box-shadow(level-3);
    transform: translateY(-1px);
  }
  label{
    font-size: 1.125rem;
    max-width: 90%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0.125rem;
    line-height: 1;
  }
  p{
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .badge{
    height: 1.5rem;
    font-size: 0.875rem;
    border-radius: 4px;
    font-weight: 500;
    color: $text-color;
    padding: 0.125rem 0.5rem;
    display: inline-block;
    margin-top: 0.25rem;
    margin-right: 1rem;
    &.achieved{
      background-color: lighten($success-color, 20);
    }
    &.failed{
      background-color: lighten($danger-color, 20);
    }
    &.ongoing{
      background-color: lighten($warning-color, 20);
    }
  }
}
