@import 'src/scss/variables';
@import 'src/scss/mixins';

.user-filters{
  background-color: white;
  margin-bottom: 1rem;
  margin-top: -19px;
  padding: 1.5rem 1.25rem;
  border-top: 1px solid rgba($color: #000000, $alpha: .08);
  @include box-shadow(level-1);
  border-radius: 0 0 6px 6px;
  .button-wrap{
    margin-top: 1rem;
    text-align: right;
    button{
      margin-left: 0.5rem;
    }
  }
}

.sub-skills{
  border: 1px solid rgba($color: #000000, $alpha: .08);
  padding: 1rem;
  border-radius: 4px;
  background-color: lighten($text-color, 69);
  .rail-wrap{
    height: 40px;
  }
  .rail{
    display: flex;
    .box{
      flex-grow: 1;
      text-align: center;
      font-weight: 600;
    }
  }
}

.skill-select-remove-margin{
  margin-bottom: -1rem;
}