@import "src/scss/variables";
@import "src/scss/mixins";

.profile-card {
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid rgba($color: #000000, $alpha: 0.08);
  background-color: lighten($text-color, 69);
  height: 100%;

  @include media-breakpoint-range(mobile) {
    margin-bottom: 1rem;
    height: auto;
  }

  .button-wrap {
    min-height: 30px;
  }

  .picture {
    position: relative;
    width: 100px;
    margin: 0 auto;

    button {
      position: absolute;
      top: 0;
      right: 0;
    }

    .camera {
      position: absolute;
      right: 0rem;
      top: 0rem;
      display: inline-block;
      width: 35px;
      height: 35px;
      border-radius: 20px;
      line-height: 3;
      background-color: white;
      text-align: center;
      cursor: pointer;
      @include box-shadow(level-1);
    }

    .frame {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      overflow: hidden;
      border-radius: 50px;
      border: 2px solid rgba($color: #000000, $alpha: 0.16);

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .general {
    text-align: center;

    .employee-number {
      background-color: $bg-fill-grey;
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translateX(-50%);
      display: inline-block;
      border-radius: 4px;
      padding: 0.25rem 0.5rem;
      line-height: 1.2;
      font-weight: 500;
      font-size: 0.875rem;
      @include box-shadow(level-1);
    }

    .name {
      font-size: 1.25rem;
      font-family: $body-font;
      color: $text-color;
      font-weight: 500;
      margin-bottom: 0.25rem;
      text-align: center;
      margin-top: 0.5rem;
    }

    .title {
      margin-bottom: 1rem;
    }

    .manager {
      color: lighten($color: $text-color, $amount: 40);
      font-size: 13px;
    }

    .mvp {
      background-color: #bbf0f3;
      background-image: linear-gradient(315deg, #bbf0f3 0%, #f6d285 74%);
      display: inline-block;
      border-radius: 4px;
      padding: 0.25rem 0.5rem;
      line-height: 1.2;
      font-weight: 500;
      font-size: 0.875rem;
      margin-right: 0.5rem;
    }

    .email {
      color: lighten($color: $text-color, $amount: 40);
      margin-bottom: 0;
    }
  }
}
